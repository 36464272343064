import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  TextViewWrapper,
  TextViewNoteTitle,
  TextViewNoteTitleText,
  TextViewNoteDate,
  TextViewNoteDateText,
  TextViewNoteBooks,
  TextViewNoteBooksButton,
  TextViewNoteBooksButtonText,
  TextViewNoteText,
  TextViewNoteButtonContainer,
  TextViewNoteEditButton,
  TextViewNoteEditButtonText,
} from "./TextNoteView.vanilla.css"
import { timeDateRenderer } from "../../utils"

React.useLayoutEffect = React.useEffect

export interface TextNoteViewProps {
  note: any
  cachedHTML: string
  title: string
  notebooks: string[]
  createdDate: Date
  _id: string
  selectedNoteBooks: string[]
}

export const TextNoteView = ({
  cachedHTML,
  createdDate,
  title,
  notebooks,
  _id,
}: TextNoteViewProps) => {
  return (
    <div className={TextViewWrapper}>
      <div className={TextViewNoteTitle}>
        <div className={TextViewNoteTitleText}>{title}</div>
      </div>
      {notebooks && notebooks.length > 0 ? (
        <div className={TextViewNoteDate}>
          <div className={TextViewNoteDateText}>
            Created - {timeDateRenderer(createdDate)}
          </div>
        </div>
      ) : (
        <div>
          <div className={TextViewNoteDateText}>
            Created - {timeDateRenderer(createdDate)}
          </div>
        </div>
      )}

      {notebooks && notebooks.length > 0 && (
        <div className={TextViewNoteBooks}>
          {notebooks &&
            notebooks.map((nb, i) => (
              <Link
                className={TextViewNoteBooksButton}
                key={nb}
                to={`/notebooks?notebook=${nb}`}
              >
                <div className={TextViewNoteBooksButtonText}>{nb}</div>
              </Link>
            ))}
        </div>
      )}

      <div
        className={TextViewNoteText}
        dangerouslySetInnerHTML={{
          __html: cachedHTML,
        }}
      />
      <div className={TextViewNoteButtonContainer}>
        <Link to={`/edit/note/${_id}`} className={TextViewNoteEditButton}>
          <div className={TextViewNoteEditButtonText}>Edit</div>
        </Link>
      </div>
    </div>
  )
}
