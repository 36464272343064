import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthedUserContext } from "../../providers"

interface RequireAuthProps {
  children: JSX.Element
  redirect?: boolean
}

export const RequireAuth = ({ children, redirect }: RequireAuthProps) => {
  const [user, loading] = useContext(AuthedUserContext)

  if (loading) {
    return <></>
  } else if (user?.uid && !loading) {
    return children
  } else {
    if (redirect) {
      return <Navigate to="/login" />
    } else {
      return <></>
    }
  }
}
