import React, { useEffect, useState, useContext, useCallback } from "react"
import { NoteBook, ContentType } from "../types"
import { DocumentData } from "firebase/firestore"
import {
  newDocument,
  fetchAllDoc,
  fetchDocByTitle,
  addContentCount,
  removeContentCount,
} from "../api/content"
import { AuthedUserContext, ContentContext } from "../providers"

export const useNotebooks = () => {
  const [notebooksLoading, setNoteBooksLoading] = useState<boolean>(false)
  const [notebooksSaving, setNoteBooksSaving] = useState<boolean>(false)
  const { notebooks, setNotebooks } = useContext(ContentContext)
  const [user] = useContext(AuthedUserContext)

  const fetchNotebooks = useCallback(async () => {
    const data = (await fetchAllDoc(user?.uid, "notebooks")) as NoteBook[]
    if (data) {
      setNotebooks(data)
    }
  }, [setNotebooks, user])

  const addNotebook = useCallback(
    async (title: string) => {
      const data = {
        title,
        contentCount: [],
      }
      const add = await newDocument(data, user?.uid, "notebooks")
      return add
    },
    [user]
  )

  const handleNewNotebooks = async (
    notebooks: string[],
    _id: string,
    type: ContentType
  ) => {
    setNoteBooksLoading(true)
    await updateNotebooks(notebooks, _id, type)
  }

  const handleUpdateNotebooks = async (
    nb: string[],
    deleteNotebooks: string[],
    _id: string,
    type: ContentType
  ) => {
    if (nb.length > 0) {
      await updateNotebooks(nb, _id, type)
    }
    if (deleteNotebooks.length > 0) {
      await removeFromNotebooks(deleteNotebooks, _id, type)
    }
  }

  const handleDeleteNotebooks = async (
    nb: string[],
    _id: string,
    type: ContentType
  ) => {
    await removeFromNotebooks(nb, _id, type)
  }

  const updateNotebooks = async (
    nb: string[],
    _id: string,
    type: ContentType
  ) => {
    nb.forEach(async (notebook) => {
      const checkedNotebook = await fetchDocByTitle<NoteBook>(
        user?.uid,
        "notebooks",
        notebook
      )
      if (checkedNotebook && checkedNotebook.length === 1) {
        if (checkedNotebook[0]._id) {
          const data = {
            _id,
            type,
          }
          await addContentCount(
            data,
            user?.uid,
            "notebooks",
            checkedNotebook[0]._id
          )
        }
        setNoteBooksSaving(false)
      }
    })
  }

  const removeFromNotebooks = async (
    nb: string[],
    _id: string,
    type: ContentType
  ) => {
    nb.forEach(async (notebook) => {
      const checkedNotebook = await fetchDocByTitle<NoteBook>(
        user?.uid,
        "notebooks",
        notebook
      )
      if (checkedNotebook && checkedNotebook.length === 1) {
        if (checkedNotebook[0]._id) {
          const data = {
            _id,
            type,
          }
          await removeContentCount(
            data,
            user?.uid,
            "notebooks",
            checkedNotebook[0]._id
          )
          fetchNotebooks()
          setNoteBooksSaving(false)
        }
      } else {
        setNoteBooksSaving(false)
      }
    })
  }

  return {
    notebooks,
    addNotebook,
    fetchNotebooks,
    handleNewNotebooks,
    handleUpdateNotebooks,
    handleDeleteNotebooks,
  } as const
}
