import { useState, useContext } from "react"
import { AuthedUserContext, ContentContext } from "../providers"
import { DateEntry, ContentCount } from "../schema"
import {
  saveDate,
  getDate,
  updateDate,
  getDatesRange,
  getDatesDefault,
} from "../api/content"

export const useDates = () => {
  const [datesLoading, setDatesLoading] = useState<boolean>(false)
  const { dates, setDates } = useContext(ContentContext)
  const [dateTarget, setDateTarget] = useState<DateEntry | null>(null)
  const [user] = useContext(AuthedUserContext)

  const createTodayDate = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (user) {
      const dateObject = {
        uid: user.uid,
        date: today,
        contentCount: [],
      }
      setDates([dateObject])
      setDateTarget(dateObject)
    }
  }

  const fetchDates = async (start?: Date, end?: Date) => {
    setDatesLoading(true)
    let dates = []
    if (start && end) {
      dates = await getDatesRange(user?.uid, start, end)
    } else {
      dates = await getDatesDefault(user?.uid)
    }

    if (dates.length === 0) {
      createTodayDate()
    } else {
      const d: DateEntry[] = dates.map((date) => {
        return {
          _id: date._id,
          uid: date.uid,
          contentCount: date.contentCount,
          date: date.date,
        }
      })
      const td = new Date()
      td.setHours(0, 0, 0, 0)
      if (JSON.stringify(d[0].date) === JSON.stringify(td)) {
        setDates(d)
        setDateTarget(d[0])
        setDatesLoading(false)
      } else {
        const tempDates = [...d]
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        tempDates.unshift({
          uid: user?.uid,
          date: today,
          contentCount: [],
        })
        setDates(tempDates)
        setDateTarget(tempDates[0])
        setDatesLoading(false)
      }
    }
  }

  const createDate = async () => {
    setDatesLoading(true)
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    const date: DateEntry = {
      uid: user?.uid,
      date: d,
      contentCount: [],
    }
    const data = await saveDate(date)
    const gd = await getDate(user.uid, d)
    if (gd) {
      setDateTarget(gd[0])
      setDatesLoading(false)
    }
    return data
  }

  const updateDateCount = async (
    uid: string,
    dateId: string,
    entry: ContentCount
  ) => {
    setDatesLoading(true)
    await updateDate(uid, dateId, entry)
    setDatesLoading(false)
  }

  const setDateTargetAsync = async (date: DateEntry) => {
    const dateSetter = async () => {
      setDateTarget(date)
    }
    return dateSetter
  }

  const isToday = (date: Date): boolean => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    return JSON.stringify(date) === JSON.stringify(d)
  }

  return {
    datesLoading,
    dates,
    dateTarget,
    fetchDates,
    updateDateCount,
    setDateTargetAsync,
    createDate,
    isToday,
  } as const
}
