import {
  createPluginFactory,
  someHtmlElement,
  ToggleMarkPlugin,
} from "@udecode/plate-core"
// import { Hashtag } from "./Hashtag"
export const HASHTAG = "hashtag"

export const createHashtagPlugin = createPluginFactory<ToggleMarkPlugin>({
  key: HASHTAG,
  isLeaf: true,
  deserializeHtml: {
    rules: [
      {
        validNodeName: ["hashtag"],
      },
    ],
    query: (el) =>
      !someHtmlElement(el, (node) => node.style.textDecoration === "none"),
  },
})
