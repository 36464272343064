export const timeDateRenderer = (createDateRender: Date) => {
  const minutes =
    createDateRender.getMinutes() > 10
      ? createDateRender.getMinutes()
      : `${0}${createDateRender.getMinutes()}`
  const hours = createDateRender.getHours()
  const date = createDateRender.getDate()
  const dayName = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(createDateRender)
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    createDateRender
  )
  const year = createDateRender.getFullYear()

  return `${hours}:${minutes} ${dayName} ${date} ${month} ${year}`
}

export const dateRender = (createDateRender: Date) => {
  const date = createDateRender.getDate()
  const month = new Intl.DateTimeFormat("en-US", { month: "numeric" }).format(
    createDateRender
  )
  const year = createDateRender.getFullYear()

  return `${date}/${month}/${year}`
}
