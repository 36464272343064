import React from "react"
import {
  NoNotesWrapper,
  NoNotesHeading,
  NoNotesHeadingText,
  NoNotesInstructions,
  NoNotesInstructionsText,
} from "./Styles"

interface Props {
  offset?: number
  title: string
  message: string
}

export const NoNotes = ({ offset, title, message }: Props) => (
  <NoNotesWrapper offset={offset}>
    <NoNotesHeading>
      <NoNotesHeadingText>{title}</NoNotesHeadingText>
    </NoNotesHeading>
    <NoNotesInstructions>
      <NoNotesInstructionsText>{message}</NoNotesInstructionsText>
    </NoNotesInstructions>
  </NoNotesWrapper>
)
