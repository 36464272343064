import React from "react"

export const Note = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H13C13.1313 21.0002 13.2614 20.9744 13.3828 20.9241C13.5041 20.8738 13.6143 20.8 13.707 20.707L20.707 13.707C20.7892 13.6218 20.8556 13.5225 20.903 13.414C20.917 13.384 20.925 13.353 20.936 13.321C20.9645 13.2373 20.9816 13.1502 20.987 13.062C20.989 13.041 21 13.021 21 13V5C21 3.897 20.103 3 19 3V3ZM5 5H19V12H13C12.7348 12 12.4804 12.1054 12.2929 12.2929C12.1054 12.4804 12 12.7348 12 13V19H5V5ZM14 17.586V14H17.586L14 17.586Z"
      fill="white"
    />
  </svg>
)
