import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { auth } from "../../firebase/firebase"
import { sendPasswordReset } from "../../firebase/auth"
import {
  LoginPage,
  LoginIntro,
  LoginTitle,
  LoginIntroText,
  LoginInputFields,
  LoginInputContainer,
  LoginButton,
  ResetRegisterContainer,
  LinkContainer,
  StyledLink,
} from "../Login/style"
import { ButtonText } from "../../components"

export const Reset = () => {
  const [email, setEmail] = useState("")
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) return
    if (user) navigate("/")
  }, [user, loading])
  return (
    <LoginPage>
      <LoginIntro>
        <LoginTitle>Work Diary</LoginTitle>
        <LoginIntroText>Reset your password</LoginIntroText>
      </LoginIntro>
      <LoginInputContainer>
        <LoginInputFields
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <LoginButton
          className="reset__btn"
          onClick={() => sendPasswordReset(email)}
        >
          <ButtonText>Send password reset email</ButtonText>
        </LoginButton>
        <ResetRegisterContainer>
          <LinkContainer>
            Don't have an account? <StyledLink to="/signup">Sign up</StyledLink>{" "}
            now.
          </LinkContainer>
        </ResetRegisterContainer>
      </LoginInputContainer>
    </LoginPage>
  )
}
