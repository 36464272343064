import React, { useState, useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthedUserContext } from "../../providers"
import {
  logInWithEmailAndPassword,
  signInWithGithub,
  signInWithGoogle,
  signInWithFacebook,
  signInWithTwitter,
} from "../../firebase/auth"
import {
  LoginPage,
  LoginIntro,
  LoginTitle,
  LoginIntroText,
  LoginInputFields,
  LoginInputContainer,
  LoginButton,
  LoginWithServiceButton,
  LoginIconWrapper,
  ResetRegisterContainer,
  LinkContainer,
  StyledLink,
} from "./style"
import { ButtonText, Icons } from "../../components"

export const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [user, loading] = useContext(AuthedUserContext)

  if (user) {
    return <Navigate to="/" replace />
  } else {
    return (
      <LoginPage>
        <LoginIntro>
          <LoginTitle>Work Diary</LoginTitle>
          <LoginIntroText>
            A notes app to record and contextualise your life at work.
          </LoginIntroText>
        </LoginIntro>
        <LoginInputContainer>
          <LoginInputFields
            type="text"
            className="login__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-MAIL"
          />
          <LoginInputFields
            type="password"
            className="login__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="PASSWORD"
          />
          <LoginButton
            className="login__btn"
            onClick={() => logInWithEmailAndPassword(email, password)}
          >
            <ButtonText>Login</ButtonText>
          </LoginButton>
          <LoginWithServiceButton
            className="login__btn login__google"
            onClick={signInWithGoogle}
          >
            <LoginIconWrapper>
              <Icons.Google />
            </LoginIconWrapper>
            <ButtonText>Login with Google</ButtonText>
          </LoginWithServiceButton>
          <LoginWithServiceButton
            className="login__btn login__google"
            onClick={signInWithGithub}
          >
            <LoginIconWrapper>
              <Icons.Github />
            </LoginIconWrapper>
            <ButtonText>Login with Github</ButtonText>
          </LoginWithServiceButton>
          <LoginWithServiceButton
            className="login__btn login__facebook"
            onClick={signInWithFacebook}
          >
            <LoginIconWrapper>
              <Icons.Facebook />
            </LoginIconWrapper>
            <ButtonText>Login with Facebook</ButtonText>
          </LoginWithServiceButton>
          <LoginWithServiceButton
            className="login__btn login__twitter"
            onClick={signInWithTwitter}
          >
            <LoginIconWrapper>
              <Icons.Twitter />
            </LoginIconWrapper>
            <ButtonText>Login with Twitter</ButtonText>
          </LoginWithServiceButton>
          <ResetRegisterContainer>
            <LinkContainer>
              <StyledLink to="/reset">Forgot Password</StyledLink>
            </LinkContainer>
            <LinkContainer>
              Don't have an account?{" "}
              <StyledLink to="/signup">Sign up</StyledLink> now.
            </LinkContainer>
          </ResetRegisterContainer>
        </LoginInputContainer>
      </LoginPage>
    )
  }
}
