import { LogOut } from "./LogOut"
import { Note } from "./Note"
import { UnorderedList } from "./UnOrderedList"
import { OrderedList } from "./OrderedList"
import { Save } from "./Save"
import { NoteBook } from "./NoteBook"
import { Topic } from "./Topic"
import { Link } from "./Link"
import { Menu } from "./Menu"
import { Filter } from "./Filter"
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "./Chevrons"
import { Github, Google, Facebook, Twitter, Apple } from "./BrandIcons"
import { Calendar } from "./Calendar"

export const Icons = {
  LogOut,
  Note,
  UnorderedList,
  OrderedList,
  Save,
  NoteBook,
  Topic,
  Link,
  Menu,
  Filter,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Github,
  Google,
  Facebook,
  Twitter,
  Apple,
  Calendar,
}
