import styled from "styled-components"

export const MultiButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.spacing.x1};
  padding: ${(props) => props.theme.spacing.x1}
    ${(props) => props.theme.spacing.x8};
  margin-right: ${(props) => props.theme.spacing.x6};
  box-shadow: 0px 2px 4px 0px ${(props) => props.theme.colors.black.l1};

  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.x1}
      ${(props) => props.theme.spacing.x4};
  }

  &:hover {
    cursor: pointer;
  }
`

export const ButtonText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.spacing.x3};
  letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.l1};

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.spacing.x3};
  }
`

export const BlueButton = styled(Button)`
  background: ${(props) => props.theme.colors.blue.l1};
`

export const GreenButton = styled(Button)`
  background: ${(props) => props.theme.colors.green.l1};
`

export const RedButton = styled(Button)`
  background: ${(props) => props.theme.colors.red.l1};
`

export const GreyButton = styled(Button)`
  background: ${(props) => props.theme.colors.black.l4};
  cursor: not-allowed;
`
