import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"

// Annoying fix for useLayout BS
if (process.env.NODE_ENV !== "production") {
  console.warn = console.error = () => null
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://98bb380ba85942cb94c04967c96bd5e3@o1161610.ingest.sentry.io/6247876",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
