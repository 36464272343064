import {
  collection,
  addDoc,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore"
import { db } from "../../firebase"
import { TextNote, TextNoteSchema } from "../../schema"

export const saveTextNote = async ({
  uid,
  note,
  html,
  associatedDate,
  createdDate,
  lastUpdate,
  title,
  tags,
  notebooks,
}: TextNote) => {
  try {
    const res = await addDoc(collection(db, `users/${uid}/notes`), {
      uid: uid,
      note: note,
      html: html,
      associatedDate: associatedDate,
      createdDate: createdDate,
      lastUpdate: lastUpdate,
      title: title,
      tags: tags,
      notebooks: notebooks,
    })
    return res
  } catch (error) {
    console.log(error)
  }
}

export const updateTextNote = async (
  uid: string,
  _id: string,
  note: TextNote,
  html: string,
  title: string,
  tags: string[],
  notebooks: string[]
) => {
  try {
    const notesRef = doc(db, `users/${uid}/notes`, _id)
    const res = await updateDoc(notesRef, {
      note,
      html,
      lastUpdate: new Date(),
      title,
      tags,
      notebooks,
    })
    return { res, update: "completed" }
  } catch (error) {
    console.log(error)
  }
}

export const getTextNote = async (uid: string, _id: string) => {
  try {
    const res = await getDoc(doc(db, `users/${uid}/notes`, _id))
    const data = {
      _id: res.id,
      note: res.data()?.note,
      uid: res.data()?.uid,
      html: res.data()?.html,
      associatedDate: res.data()?.associatedDate.toDate(),
      createdDate: res.data()?.createdDate.toDate(),
      lastUpdate: res.data()?.lastUpdate.toDate(),
      tags: res.data()?.tags,
      notebooks: res.data()?.notebooks,
      title: res.data()?.title,
    }

    return TextNoteSchema.parse(data)
  } catch (error) {
    console.log(error)
  }
}

export const deleteTextNote = async (uid: string, _id: string) => {
  try {
    const res = await deleteDoc(doc(db, `users/${uid}/notes`, _id))
    return { res, update: "completed" }
  } catch (error) {
    console.log(error)
  }
}

export const getAllNotesAsc = async (uid: string) => {
  const datesRef = collection(db, `users/${uid}/notes`)
  const q = query(datesRef, where("uid", "==", uid), orderBy("createdDate"))

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      note: doc.data().note,
      uid: doc.data().uid,
      html: doc.data().html,
      associatedDate: doc.data().associatedDate.toDate(),
      createdDate: doc.data().createdDate.toDate(),
      lastUpdate: doc.data().lastUpdate.toDate(),
      tags: doc.data().tags,
      notebooks: doc.data().notebooks,
      title: doc.data().title,
    }

    return TextNoteSchema.parse(data)
  })
}

export const getAllNotesDesc = async (uid: string) => {
  const notesRef = collection(db, `users/${uid}/notes`)
  const q = query(
    notesRef,
    where("uid", "==", uid),
    orderBy("createdDate", "desc")
  )

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      note: doc.data().note,
      uid: doc.data().uid,
      html: doc.data().html,
      associatedDate: doc.data().associatedDate.toDate(),
      createdDate: doc.data().createdDate.toDate(),
      lastUpdate: doc.data().lastUpdate.toDate(),
      tags: doc.data().tags,
      notebooks: doc.data().notebooks,
      title: doc.data().title,
    }

    return TextNoteSchema.parse(data)
  })
}

export const getNotesByDateRange = async (
  uid: string,
  start: Date,
  end: Date
) => {
  const notesRef = collection(db, `users/${uid}/notes`)
  const q = query(
    notesRef,
    where("associatedDate", ">=", start),
    where("associatedDate", "<=", end),
    orderBy("associatedDate", "desc"),
    orderBy("createdDate", "desc")
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      note: doc.data().note,
      uid: doc.data().uid,
      html: doc.data().html,
      associatedDate: doc.data().associatedDate.toDate(),
      createdDate: doc.data().createdDate.toDate(),
      lastUpdate: doc.data().lastUpdate.toDate(),
      tags: doc.data().tags,
      notebooks: doc.data().notebooks,
      title: doc.data().title,
    }

    return TextNoteSchema.parse(data)
  })
}

export const notebooksPageQueryBooksTopics = async (
  uid: string,
  start: Date,
  end: Date,
  notebooks: string[],
  topics: string[]
) => {
  const notesRef = collection(db, `users/${uid}/notes`)
  const q = query(
    notesRef,
    where("associatedDate", ">=", start),
    where("associatedDate", "<=", end),
    where("tags", "array-contains-any", topics),
    orderBy("associatedDate", "desc"),
    orderBy("createdDate", "desc")
  )

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs
    .map((doc: any) => {
      const data = {
        _id: doc.id,
        note: doc.data().note,
        uid: doc.data().uid,
        html: doc.data().html,
        associatedDate: doc.data().associatedDate.toDate(),
        createdDate: doc.data().createdDate.toDate(),
        lastUpdate: doc.data().lastUpdate.toDate(),
        tags: doc.data().tags,
        notebooks: doc.data().notebooks,
        title: doc.data().title,
      }
      return TextNoteSchema.parse(data)
    })
    .filter((doc) => doc.notebooks.some((r) => notebooks.includes(r)))
}

export const notebooksPageQueryBooksOnly = async (
  uid: string,
  start: Date,
  end: Date,
  notebooks: string[]
) => {
  const notesRef = collection(db, `users/${uid}/notes`)

  const q = query(
    notesRef,
    where("associatedDate", ">=", start),
    where("associatedDate", "<=", end),
    where("notebooks", "array-contains-any", notebooks),
    orderBy("associatedDate", "desc"),
    orderBy("createdDate", "desc")
  )

  const querySnapshot = await getDocs(q)

  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      note: doc.data().note,
      uid: doc.data().uid,
      html: doc.data().html,
      associatedDate: doc.data().associatedDate.toDate(),
      createdDate: doc.data().createdDate.toDate(),
      lastUpdate: doc.data().lastUpdate.toDate(),
      tags: doc.data().tags,
      notebooks: doc.data().notebooks,
      title: doc.data().title,
    }

    return TextNoteSchema.parse(data)
  })
}

export const notebooksPageQueryTopicsOnly = async (
  uid: string,
  start: Date,
  end: Date,
  topics: string[]
) => {
  const notesRef = collection(db, `users/${uid}/notes`)
  const q = query(
    notesRef,
    where("associatedDate", ">=", start),
    where("associatedDate", "<=", end),
    where("tags", "array-contains-any", topics),
    orderBy("associatedDate", "desc"),
    orderBy("createdDate", "desc")
  )

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      note: doc.data().note,
      uid: doc.data().uid,
      html: doc.data().html,
      associatedDate: doc.data().associatedDate.toDate(),
      createdDate: doc.data().createdDate.toDate(),
      lastUpdate: doc.data().lastUpdate.toDate(),
      tags: doc.data().tags,
      notebooks: doc.data().notebooks,
      title: doc.data().title,
    }

    return TextNoteSchema.parse(data)
  })
}
