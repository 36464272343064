// https://coolors.co/114b5f-028090-e4fde1-335070-f45b69

export const spacing = {
  x1: "4px",
  x2: "8px",
  x3: "12px",
  x4: "16px",
  x5: "20px",
  x6: "24px",
  x7: "28px",
  x8: "32px",
  x9: "36px",
  x10: "40px",
  x11: "44px",
  x12: "48px",
  x13: "52px",
  x14: "56px",
  x15: "60px",
  x16: "64px",
  x17: "68px",
  x18: "72px",
  x19: "76px",
  x20: "80px",
  x21: "84px",
  x22: "88px",
  x23: "92px",
  x24: "96px",
  x25: "100px",
}

export const colors = {
  black: {
    l1: "#0D1F24",
    l2: "#25353A",
    l3: "#3C4B50",
    l4: "#546165",
    l5: "#6C777B",
    l9: "#3B494E",
  },
  pink: {
    l1: "#DA2C7E",
  },
  blue: {
    l1: "#2CAFE8",
  },
  green: {
    l1: "#31C871",
  },
  orange: {
    l1: "#F0982C",
  },
  white: {
    l1: "#fff",
    l2: "#E7E9E9",
  },
  purple: {
    l1: "#6F1D6F",
    l2: "#89478A",
  },
  red: {
    l1: "#EB5757",
  },
}

export const gradient = {
  background:
    "linear-gradient(102.69deg, #142E35 0.38%, #000000 33.59%, #280303 100%);",
}

export const typography = {
  letterSpacing_10: "0.1em",
}

export const animation = {
  transition: "0.25s ease-in-out",
}

export const media = {
  mobile: "768px",
}

export const mainTheme = {
  gradient,
  colors,
  spacing,
  typography,
  animation,
  media,
}
