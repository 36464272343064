import styled from "styled-components"

export const TextInputField = styled.input`
  width: 100%;
  background: ${(props) => props.theme.colors.black.l3};
  padding: ${(props) => props.theme.spacing.x4};
  border-radius: 5px;
  border: 1px solid #eee;
  font-size: 18px;
`
