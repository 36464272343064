import React from "react"
import { Route, Routes } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { Login, SignUp, Reset, Diary, Settings, EditNote } from "./pages"
import { RequireAuth } from "./components"
import { AuthedUserProvider, ContentProvider } from "./providers"
import { mainTheme } from "./themes"
import { MenuSystem } from "./components"
import { BackgroundContainer } from "./App.vanilla.css"

const App = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <AuthedUserProvider>
        <ContentProvider>
          <div className="app">
            <div className={BackgroundContainer}>
              <RequireAuth>
                <MenuSystem />
              </RequireAuth>
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth redirect>
                      <Diary />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <RequireAuth redirect>
                      <Settings />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/new/note"
                  element={
                    <RequireAuth redirect>
                      <EditNote edit={false} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/edit/note/:id"
                  element={
                    <RequireAuth redirect>
                      <EditNote edit={true} />
                    </RequireAuth>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/reset" element={<Reset />} />
              </Routes>
            </div>
          </div>
        </ContentProvider>
      </AuthedUserProvider>
    </ThemeProvider>
  )
}

export default App
