import {
  collection,
  where,
  query,
  getDocs,
  addDoc,
  orderBy,
  doc,
  updateDoc,
  arrayUnion,
  limit,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore"
import { db } from "../../firebase"
import { DateEntry, DateEntrySchema, ContentCount } from "../../schema"

export const saveDate = async (props: DateEntry) => {
  const res = await addDoc(collection(db, `users/${props.uid}/dates`), {
    uid: props.uid,
    date: props.date,
    contentCount: props.contentCount,
  })
  return res
}

export const getDate = async (uid: string, date: Date) => {
  const datesRef = collection(db, `users/${uid}/dates`)
  const q = query(datesRef, where("uid", "==", uid), where("date", "==", date))

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      ...doc.data(),
    }

    return DateEntrySchema.parse({
      _id: data._id,
      uid: data.uid,
      date: data.date.toDate(),
      contentCount: data.contentCount,
    })
  })
}

export const getAllDates = async (uid: string) => {
  const datesRef = collection(db, `users/${uid}/dates`)
  const q = query(datesRef, where("uid", "==", uid), orderBy("date", "desc"))

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      ...doc.data(),
    }

    return DateEntrySchema.parse({
      _id: data._id,
      uid: data.uid,
      date: data.date.toDate(),
      contentCount: data.contentCount,
    })
  })
}

export const getDatesDefault = async (uid: string) => {
  const datesRef = collection(db, `users/${uid}/dates`)
  const q = query(datesRef, limit(40), orderBy("date", "desc"))

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      ...doc.data(),
    }

    return DateEntrySchema.parse({
      _id: data._id,
      uid: data.uid,
      date: data.date.toDate(),
      contentCount: data.contentCount,
    })
  })
}

export const getDatesRange = async (uid: string, start: Date, end: Date) => {
  const datesRef = collection(db, `users/${uid}/dates`)
  const q = query(
    datesRef,
    where("uid", "==", uid),
    where("date", ">=", start),
    where("date", "<=", end),
    orderBy("date", "desc")
  )

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data = {
      _id: doc.id,
      ...doc.data(),
    }

    return DateEntrySchema.parse({
      _id: data._id,
      uid: data.uid,
      date: data.date.toDate(),
      contentCount: data.contentCount,
    })
  })
}

export const updateDate = async (
  uid: string,
  _id: string,
  entry: ContentCount
) => {
  try {
    const datesRef = doc(db, `users/${uid}/dates`, _id)
    await updateDoc(datesRef, {
      contentCount: arrayUnion(entry),
    })
    return "update complete"
  } catch (error) {
    console.log(error)
  }
}

export const removeFromDate = async (
  uid: string,
  dateId: string,
  entryId: string
) => {
  try {
    const datesRef = doc(db, `users/${uid}/dates`, dateId)
    await updateDoc(datesRef, {
      contentCount: arrayRemove({ _id: entryId, type: "notes" }),
    })
  } catch (error) {
    console.log(error)
  }
}

export const deleteDate = async (uid: string, dateId: string) => {
  try {
    const res = await deleteDoc(doc(db, `users/${uid}/dates`, dateId))
    return { res, update: "completed" }
  } catch (error) {
    console.log(error)
  }
}
