import styled from "styled-components"
import { HeadingToolbar } from "@udecode/plate"

export const CustomToolBar = styled(HeadingToolbar)`
  border: none;
  margin: 0;
  padding: ${(props) => props.theme.spacing.x4} 0;
`

export const TextNoteEditToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ToolBarSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${(props) => props.theme.spacing.x4};

  padding: ${(props) => props.theme.spacing.x1}
    ${(props) => props.theme.spacing.x4};
  background: ${(props) => props.theme.colors.black.l4};
  border-radius: ${(props) => props.theme.spacing.x1};

  @media (max-width: 768px) {
    margin-bottom: ${(props) => props.theme.spacing.x4};
  }

  .slate-ToolbarButton {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => props.theme.spacing.x4};
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.white.l2};

    & > svg {
      & > path {
        fill: ${(props) => props.theme.colors.white.l2};
      }
    }

    &:hover {
      color: ${(props) => props.theme.colors.pink.l1};
    }

    &:active {
      color: ${(props) => props.theme.colors.pink.l1};
    }

    &.slate-ToolbarButton-active {
      color: ${(props) => props.theme.colors.pink.l1};
      & > svg {
        & > path {
          fill: ${(props) => props.theme.colors.pink.l1};
        }
      }
    }

    & > svg {
      width: 1.5rem;
      height: 1.5rem;

      &:hover {
        & > path {
          fill: ${(props) => props.theme.colors.pink.l1};
        }
      }
    }

    & s {
      &:hover {
        color: ${(props) => props.theme.colors.pink.l1};
      }
    }
  }
`
