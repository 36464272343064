import { useContext, useState } from "react"
import {
  getTag,
  addTag as addTagApi,
  updateTag as updateTagApi,
  removeContentFromTag,
  deleteTag as deleteTagApi,
  getAllTags,
} from "../api/content"
import { AuthedUserContext, ContentContext } from "../providers"
import { ContentType } from "../types"
import { Tag } from "../schema"

export const useTags = () => {
  const [user] = useContext(AuthedUserContext)
  const { tags, setTags } = useContext(ContentContext)
  const [updating, setUpdating] = useState<boolean>(false)

  const getTags = async () => {
    const allTags = await getAllTags(user?.uid)
    if (allTags) {
      setTags(allTags)
    }
  }

  const checkTag = async (tag: string) => {
    const check: Tag[] | undefined = await getTag(user?.uid, tag)
    return check
  }

  const addTag = async (tag: string, _id: string, type: ContentType) => {
    const add = await addTagApi(user?.uid, tag, _id, type)
    return add
  }

  const updateTag = async (tagId: string, _id: string, type: ContentType) => {
    const update = await updateTagApi(user?.uid, tagId, _id, type)
    return update
  }

  const removeFromTag = async (
    tagId: string,
    _id: string,
    type: ContentType
  ) => {
    const update = await removeContentFromTag(user?.uid, tagId, _id, type)
    await getTags()
    return update
  }

  const deleteTag = async (tagId: string) => {
    const update = await deleteTagApi(user?.uid, tagId)
    return update
  }

  const addOrUpdateTags = async (
    tags: string[],
    _id: string,
    type: ContentType
  ) => {
    tags.forEach(async (tag) => {
      const checkedTag = await checkTag(tag)
      if (checkedTag && checkedTag.length === 1) {
        if (checkedTag[0]._id) {
          await updateTag(checkedTag[0]._id, _id, type)
        }
        setUpdating(false)
      } else {
        await addTag(tag, _id, type)
        setUpdating(false)
      }
    })
  }

  const removeOrDeleteTags = async (
    tags: string[],
    _id: string,
    type: ContentType
  ) => {
    tags.forEach(async (tag) => {
      const checkedTag = await checkTag(tag)
      if (checkedTag && checkedTag.length === 1) {
        if (checkedTag[0]._id && checkedTag[0].contentCount?.length > 1) {
          await removeFromTag(checkedTag[0]._id, _id, type)
          setUpdating(false)
        } else if (checkedTag[0]._id) {
          await deleteTag(checkedTag[0]._id)
          await getTags()
          setUpdating(false)
        }
      } else {
        setUpdating(false)
      }
    })
  }

  const handleNewTags = async (
    tags: string[],
    _id: string,
    type: ContentType
  ) => {
    setUpdating(true)
    await addOrUpdateTags(tags, _id, type)
  }

  const handleUpdateTags = async (
    newTags: string[],
    deleteTags: string[],
    _id: string,
    type: ContentType
  ) => {
    if (newTags.length > 0) {
      await addOrUpdateTags(newTags, _id, type)
    }
    if (deleteTags.length > 0) {
      await removeOrDeleteTags(deleteTags, _id, type)
    }
  }

  const handleDeleteTags = async (
    tags: string[],
    _id: string,
    type: ContentType
  ) => {
    await removeOrDeleteTags(tags, _id, type)
  }

  return {
    tags,
    getTags,
    handleNewTags,
    handleUpdateTags,
    handleDeleteTags,
    updating,
  } as const
}
