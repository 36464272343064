import { createUserWithEmailAndPassword } from "firebase/auth"
import { auth } from "../firebase"
import { addUser } from "../../api/user"

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addUser({
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    })
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}
