import React from "react"
import { Link } from "react-router-dom"
import { Icons } from "../Icons"
import {
  AddNewMenuContainer,
  AddNewMenuContainerOffset,
  AddNewText,
  AddNewButtonsContainer,
  AddNewButtonIcon,
} from "./AddNewMenu.vanilla.css"

export interface AddNewMenuProps {
  offset?: boolean
}

export const AddNewMenu = ({ offset }: AddNewMenuProps) => {
  return (
    <div className={offset ? AddNewMenuContainerOffset : AddNewMenuContainer}>
      <Link to="/new/note" className={AddNewButtonsContainer}>
        <span className={AddNewText}>Add New</span>
        <div className={AddNewButtonIcon}>
          <Icons.Note />
        </div>
      </Link>
    </div>
  )
}
