import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { TextNote } from "../../schema"
import { useNotes, useNotebooks } from "../../hooks"
import { TextNoteEditor } from "../../components"
import { EditNoteContainer, EditNoteWrapper } from "./EditNote.vanilla.css"

interface EditNoteProps {
  edit: boolean
}

export const EditNote = ({ edit }: EditNoteProps) => {
  const [note, setNote] = useState<TextNote | undefined>(undefined)
  const { id } = useParams()
  const navigate = useNavigate()
  const { getNote, deleteNote } = useNotes()
  const { fetchNotebooks } = useNotebooks()

  useEffect(() => {
    if (edit && id) {
      const data = async () => {
        const noteData = await getNote(id)
        await fetchNotebooks()
        setNote(noteData)
      }
      data()
    }
  }, [edit, id])

  const deleteNoteTrigger = async (id: string) => {
    if (id) {
      await deleteNote(id)
      navigate(-1)
    }
  }

  // Add callback here to navigate back on close / delete

  return (
    <div className={EditNoteContainer}>
      <div className={EditNoteWrapper}>
        {!edit && <TextNoteEditor />}
        {edit && note && (
          <TextNoteEditor
            title={note.title}
            note={note.note}
            _id={id}
            selectedNoteBooks={note.notebooks}
            deleteNote={deleteNoteTrigger}
          />
        )}
      </div>
    </div>
  )
}
