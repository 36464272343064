import React from "react"
import { useMediaQuery } from "usehooks-ts"
import {
  DatesWrapper,
  DatesWrapperTop,
  DateText,
  DateContentCountCircle,
  DateContentCountCircleText,
  DateBlockContainer,
} from "./DateBlock.vanilla.css"
import { useDates } from "../../../../hooks"

export interface DateProps {
  date: Date
  notesCount: number
  index: number
}

export const DateBlock = ({ date, notesCount, index }: DateProps) => {
  const dayName = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
    date
  )
  const dayNumber = date.getDate()
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(date)
  const year = date.getFullYear()

  return (
    <div className={DateBlockContainer}>
      <div className={index === 0 ? DatesWrapperTop : DatesWrapper}>
        <div>
          <div className={DateText}>
            {dayName.slice(0, 3)} {dayNumber} {month} {year}
          </div>
        </div>
        {notesCount !== 0 && (
          <div className={DateContentCountCircle}>
            <div className={DateContentCountCircleText}>{notesCount}</div>
          </div>
        )}
      </div>
    </div>
  )
}
