import styled from "styled-components"
import { BlueButton, SelectWrapper } from "../../../../components"

export const BlueButtonFilter = styled(BlueButton)`
  margin: 0px;
`

export const CustomSelectWraper = styled(SelectWrapper)`
  .select__control {
    font-size: 12px;
  }

  .select__menu {
    font-size: 12px;
  }

  .select__multi-value__label {
    font-size: 10px;
  }

  .select--is-disabled {
    .select__value-container,
    .select__indicators {
      transition: 0.25s ease-in-out;
      opacity: 0.25;
    }

    .select__indicator-separator {
      transition: 0.25s ease-in-out;
      opacity: 0.1;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:nth-child(1) {
    margin-right: ${(props) => props.theme.spacing.x2};
  }

  &:nth-child(2) {
    margin-left: ${(props) => props.theme.spacing.x2};
  }

  .react-calendar {
    background: ${(props) => props.theme.colors.black.l3};
    border: 0px solid #a0a096;
    font-family: "Inter";
    line-height: 1.125em;
  }
  .react-date-picker__wrapper {
    border: 0px;
    background: ${(props) => props.theme.colors.black.l3};
    padding: ${(props) => props.theme.spacing.x2};
    border-radius: ${(props) => props.theme.spacing.x1};
  }
  .react-calendar__navigation__label__labelText {
    color: ${(props) => props.theme.colors.white.l1};
    font-family: "Inter";
    text-transform: uppercase;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    background: none;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    fill: ${(props) => props.theme.colors.white.l2};
  }
  .react-calendar__navigation button:disabled {
    background: ${(props) => props.theme.colors.black.l3};
    fill: ${(props) => props.theme.colors.black.l4};
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    /* background-color: #e6e6e6; */
    background: ${(props) => props.theme.colors.black.l1};
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: ${(props) => props.theme.colors.white.l2};
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${(props) => props.theme.colors.white.l2};
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${(props) => props.theme.colors.white.l2};
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    color: ${(props) => props.theme.colors.white.l1};
  }
  .react-calendar__tile:disabled {
    background: ${(props) => props.theme.colors.black.l4};
    color: ${(props) => props.theme.colors.black.l3};
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: ${(props) => props.theme.colors.black.l1};
  }
  .react-calendar__tile--now {
    background: ${(props) => props.theme.colors.orange.l1};
    color: ${(props) => props.theme.colors.black.l1};
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${(props) => props.theme.colors.black.l1};
  }
  .react-calendar__tile--hasActive {
    background: ${(props) => props.theme.colors.blue.l1};
    color: ${(props) => props.theme.colors.black.l1};
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: ${(props) => props.theme.colors.blue.l1};
  }
  .react-calendar__tile--active {
    background: ${(props) => props.theme.colors.blue.l1};
    color: ${(props) => props.theme.colors.black.l1};
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${(props) => props.theme.colors.blue.l1};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
    color: ${(props) => props.theme.colors.black.l1};
  }
  .react-date-picker__clear-button {
    display: none;
  }
  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider {
    color: ${(props) => props.theme.colors.white.l1};
    transition: 0.25s ease-in-out;
  }

  .react-date-picker__calendar-button svg {
    fill: ${(props) => props.theme.colors.white.l1} !important;
    transition: 0.25s ease-in-out;
  }

  .react-date-picker--disabled {
    background: none;

    .react-date-picker__inputGroup__input,
    .react-date-picker__inputGroup__divider {
      color: ${(props) => props.theme.colors.black.l2};
      transition: 0.25s ease-in-out;
    }

    .react-date-picker__calendar-button svg {
      fill: ${(props) => props.theme.colors.black.l2} !important;
      transition: 0.25s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`
