import styled from "styled-components"
import { Button } from "../../components"

export const TextNoteEditorContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background: ${(props) => props.theme.colors.black.l2};
  border-radius: ${(props) => props.theme.spacing.x1};
`

export const TextNotesEditorWrapper = styled.div`
  flex: 1;
  padding: ${(props) => props.theme.spacing.x8};
  width: 100%;
`

export const TextNotesEditorInputTitle = styled.input`
  width: 100%;
  background: ${(props) => props.theme.colors.black.l3};
  padding: ${(props) => props.theme.spacing.x4};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.theme.spacing.x6};
  letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
  color: ${(props) => props.theme.colors.white.l2};
  border: none;
  border-radius: ${(props) => props.theme.spacing.x1};

  &::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => props.theme.spacing.x6};
    letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.spacing.x4};
    }
  }

  &:focus-visible {
    /* border-bottom: 1px solid ${(props) => props.theme.colors.purple.l2}; */
    outline: none;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: ${(props) => props.theme.colors.purple.l2};
    }
  }

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.spacing.x4};
    padding: ${(props) => props.theme.spacing.x2}
      ${(props) => props.theme.spacing.x4};
  }
`

export const SlateWrapper = styled.div``

export const TitleWrapper = styled.div``

export const SlateToolBarWrapper = styled.div``

export const SlateTextEditorWrapper = styled.div`
  div[role="textbox"] {
    background: ${(props) => props.theme.colors.black.l3};
    border-radius: ${(props) => props.theme.spacing.x1};
    margin-bottom: ${(props) => props.theme.spacing.x8};
    color: ${(props) => props.theme.colors.white.l2};
    font-size: ${(props) => props.theme.spacing.x4};
    min-height: 200px !important;

    &:focus-visible {
      border-bottom: 1px solid ${(props) => props.theme.colors.purple.l2};
      outline: none;
    }

    h3,
    h4 {
      color: ${(props) => props.theme.colors.white.l2};
    }
  }
`

export const NoteBooksWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing.x4};
`

export const NoteBooksAdd = styled.div``

export const NoteBooksAddText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.spacing.x4};
  letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white.l2};

  &:hover {
    cursor: pointer;
  }
`

export const RedButtonNoMargin = styled(Button)`
  background: ${(props) => props.theme.colors.red.l1};
  margin: 0;
`

export const ButtonGroupLeft = styled.div`
  display: flex;
`

export const ButtonGroupRight = styled.div`
  display: flex;
`

export const SavingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.white.l2};
  animation: opacity 1s ease-in-out infinite;

  @keyframes opacity {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.5;
    }
  }

  svg {
    fill: ${(props) => props.theme.colors.white.l2};
  }
`

export const SavingWrapperText = styled.span`
  margin-left: ${(props) => props.theme.spacing.x3};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`
