import { useContext } from "react"
import { AuthedUserContext } from "../providers"

export const useUsers = () => {
  const [user, loading, userData, loadingUserData] =
    useContext(AuthedUserContext)

  return {
    user,
    userData,
    loading,
    loadingUserData,
  }
}
