import styled from "styled-components"
import { Link } from "react-router-dom"
import { TextInputField, Button } from "../../components"

export const LoginPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.white.l2};
`

export const LoginIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoginTitle = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.spacing.x10};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white.l2};
  letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
`

export const LoginIntroText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.spacing.x4};
  color: ${(props) => props.theme.colors.white.l2};
  margin-bottom: ${(props) => props.theme.spacing.x12};
`

export const LoginInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: ${(props) => props.theme.spacing.x4};
  background: ${(props) => props.theme.colors.black.l2};
  border-radius: ${(props) => props.theme.spacing.x1};
`

export const LoginInputFields = styled(TextInputField)`
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing.x6};
  border: 0px;
  outline: 0px;
  color: ${(props) => props.theme.colors.white.l2};
`

export const LoginButton = styled(Button)`
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colors.blue.l1};
  margin: 0px 0px ${(props) => props.theme.spacing.x6};
`

export const LoginWithServiceButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.blue.l1};
  margin: 0px 0px ${(props) => props.theme.spacing.x6};
  max-width: 260px;
  width: 100%;
`

export const LoginIconWrapper = styled.div`
  display: flex;
  margin-right: ${(props) => props.theme.spacing.x2};
`

export const ResetRegisterContainer = styled.div`
  width: 100%auto;
  border-top: 1px solid ${(props) => props.theme.colors.black.l4};
`

export const LinkContainer = styled.div`
  text-align: center;
  padding: ${(props) => props.theme.spacing.x2};
  color: ${(props) => props.theme.colors.white.l2};
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.blue.l1};
`
