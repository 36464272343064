import styled from "styled-components"

export const SelectWrapper = styled.div`
  .select__control {
    background: ${(props) => props.theme.colors.black.l3};
    padding: ${(props) => props.theme.spacing.x1};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
    color: ${(props) => props.theme.colors.white.l2};
    border: none;
    border-radius: ${(props) => props.theme.spacing.x1};
  }

  .select__multi-value {
    background-color: ${(props) => props.theme.colors.orange.l1};
    margin-right: ${(props) => props.theme.spacing.x2};
  }

  .select__multi-value__label {
    color: ${(props) => props.theme.colors.black.l1};
    font-weight: 600;
    font-size: ${(props) => props.theme.spacing.x3};
  }

  .select__menu {
    background: ${(props) => props.theme.colors.black.l4};
    border-radius: ${(props) => props.theme.spacing.x1};
    color: ${(props) => props.theme.colors.white.l2};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }

  .select__control--is-focused {
    outline: 0px;
    border: 0px;
    box-shadow: none;
  }

  .select__input-container {
    outline: 0px;
    color: ${(props) => props.theme.colors.white.l2};
  }

  .select__option--is-focused {
    background: ${(props) => props.theme.colors.black.l5};
  }

  .select__control {
    color: ${(props) => props.theme.colors.black.l1};
  }
`
