import React, { useContext, useState } from "react"
import { signOut } from "firebase/auth"
import { Link } from "react-router-dom"
import { useMediaQuery } from "usehooks-ts"
import { auth } from "../../firebase"
import { AuthedUserContext } from "../../providers"
import { Icons } from "../Icons"

export const logout = () => {
  signOut(auth)
}

import {
  MenuWrapper,
  MenuTop,
  MenuLinks,
  MenuLinksContainer,
  Logo,
  UserInfo,
  UserInfoCircle,
  UserInfoText,
  LogOutContainer,
  LogOutTrigger,
  MobileMenuWrapper,
  MobileMenuIcon,
  MobileMenuContent,
  MobileMenuContentOpen,
  MenuLinksContainerMobile,
} from "./MenuSystem.vanilla.css"

export const MenuSystem = () => {
  const [, , userData] = useContext(AuthedUserContext)
  const matches = useMediaQuery("(max-width: 768px)")
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  const nameShortner = () => {
    if (userData[0]) {
      const name = userData[0].name
      const names = name.split(" ")
      if (names.length === 1) {
        return `${names[0][0]}`
      } else {
        return `${names[0][0]}${names[1][0]}`
      }
    }
  }

  return (
    <>
      {!matches ? (
        <div className={MenuWrapper}>
          <div className={MenuTop}>
            <div className={UserInfo}>
              <div className={UserInfoCircle}>
                <div className={UserInfoText}>{nameShortner()}</div>
              </div>
            </div>
            <div className={MenuLinksContainer}>
              <div>
                <Link to="/" className={MenuLinks}>
                  Diary
                </Link>
                <Link to="/settings" className={MenuLinks}>
                  Settings
                </Link>
              </div>
              <div className={Logo}>Work Diary</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={MenuWrapper}>
          <div className={MobileMenuWrapper}>
            <div className={UserInfo}>
              <div className={UserInfoCircle}>
                <div className={UserInfoText}>{nameShortner()}</div>
              </div>
            </div>
            <div className={Logo}>Work Diary</div>
            <div
              className={MobileMenuIcon}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <Icons.Menu />
            </div>
          </div>
          <div
            className={
              showMobileMenu ? MobileMenuContentOpen : MobileMenuContent
            }
          >
            <div className={MenuLinksContainerMobile}>
              <Link
                to="/"
                className={MenuLinks}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                Diary
              </Link>
              <Link
                to="/settings"
                className={MenuLinks}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                Settings
              </Link>
            </div>
            <div className={LogOutContainer}>
              <div className={LogOutTrigger} onClick={() => logout()}>
                <Icons.LogOut />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
