import { Timestamp } from "firebase/firestore"

export interface User {
  uid: string
  name: string
  authProvider: "local" | "google" | "github" | "facebook" | "twitter"
  email: string
  seenWelcome?: boolean
}

export enum Tiers {
  Free,
  Paid,
}

export enum ContentType {
  notes = "notes",
}

export interface ContentCount {
  _id: string
  type: ContentType.notes
}

// export interface DateEntry {
//   _id?: string | null
//   uid: string
//   date: any // will be a timestamp
//   contentCount: ContentType[]
// }

// Dates will likely be a timestamp
// export interface TextNote {
//   _id?: string | undefined
//   uid: string
//   note: any
//   html: string
//   associatedDate: any
//   createdDate: any
//   lastUpdate: any
//   tags: string[]
//   notebooks: string[]
//   title: string
// }

// export interface Tag {
//   _id?: string
//   title: string
//   contentCount: ContentCount[]
// }

export interface NoteBook {
  _id?: string | null
  title: string
  createdDate: any
  contentCount: ContentCount[]
}

export interface EditorState {
  enabled: boolean
  updateDisplay: boolean
}
