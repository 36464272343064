import React, { createContext, useState, Dispatch, SetStateAction } from "react"
import { DateEntry, TextNote, Tag } from "../schema"
import { NoteBook } from "../types"

interface ContentProps {
  dates: DateEntry[]
  notes: TextNote[]
  tags: Tag[]
  notebooks: NoteBook[]
  triggerDateUpdate: boolean
  setDates: Dispatch<SetStateAction<DateEntry[]>>
  setTriggerDateUpdate: Dispatch<SetStateAction<boolean>>
  setNotes: Dispatch<SetStateAction<TextNote[]>>
  setTags: Dispatch<SetStateAction<Tag[]>>
  setNotebooks: Dispatch<SetStateAction<NoteBook[]>>
}

export const ContentContext = createContext<ContentProps>({
  dates: [],
  notes: [],
  tags: [],
  notebooks: [],
  triggerDateUpdate: false,
  setDates: () => null,
  setTriggerDateUpdate: () => null,
  setNotes: () => null,
  setTags: () => null,
  setNotebooks: () => null,
})

export const ContentProvider = ({ children }: { children: JSX.Element }) => {
  const [dates, setDates] = useState<DateEntry[]>([])
  const [triggerDateUpdate, setTriggerDateUpdate] = useState<boolean>(false)
  const [notes, setNotes] = useState<TextNote[]>([])
  const [tags, setTags] = useState<Tag[]>([])
  const [notebooks, setNotebooks] = useState<NoteBook[]>([])

  const values = {
    dates,
    setDates,
    triggerDateUpdate,
    setTriggerDateUpdate,
    notes,
    setNotes,
    tags,
    setTags,
    notebooks,
    setNotebooks,
  }

  return (
    <ContentContext.Provider value={values}>{children}</ContentContext.Provider>
  )
}
