import { FacebookAuthProvider, signInWithPopup } from "firebase/auth"
import { auth } from "../firebase"
import { addUser, searchUser } from "../../api/user"

const facebookProvider = new FacebookAuthProvider()

export const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider)
    const user = res.user
    const userDoc = await searchUser(user.uid)
    if (userDoc.docs.length === 0) {
      await addUser({
        uid: user.uid,
        name: user.displayName ? user.displayName : "",
        authProvider: "facebook",
        email: user.email ? user.email : "",
      })
    }
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}
