import { useState, useContext, useCallback } from "react"
import { DateEntry, TextNote } from "../schema"
import { ContentType } from "../types"
import { DocumentData } from "firebase/firestore"
import { AuthedUserContext, ContentContext } from "../providers"
import {
  getDate,
  saveTextNote,
  updateTextNote,
  getNotesByDateRange,
  getAllNotesDesc,
  deleteTextNote,
  removeFromDate,
  getTextNote,
  deleteDate,
  notebooksPageQueryBooksOnly,
  notebooksPageQueryTopicsOnly,
  notebooksPageQueryBooksTopics,
} from "../api/content"
import { useDates, useTags, useNotebooks } from "../hooks"

export const useNotes = () => {
  const [notesLoading, setNotesLoading] = useState<boolean>(false)
  const [notesSaving, setNotesSaving] = useState<boolean>(false)
  const { notes, setNotes, dates, setTriggerDateUpdate } =
    useContext(ContentContext)
  const [user] = useContext(AuthedUserContext)
  const { createDate, updateDateCount, setDateTargetAsync } = useDates()
  const { handleNewTags, handleDeleteTags, handleUpdateTags } = useTags()
  const { handleNewNotebooks, handleUpdateNotebooks, handleDeleteNotebooks } =
    useNotebooks()

  const getNotesByDates = async () => {
    setNotesLoading(true)
    const start = dates[dates.length - 1].date
    const end = dates[0].date
    const n = await getNotesByDateRange(user?.uid, start, end)
    if (n.length > 0) {
      setNotes(n)
      setNotesLoading(false)
    }
  }

  const getNotesDesc = async () => {
    const data = await getAllNotesDesc(user?.uid)
    if (data) {
      setNotes(data)
    }
  }

  const getNotesQuery = useCallback(
    async (
      start: Date,
      end: Date,
      notebooks: string[] | null,
      topics: string[] | null
    ) => {
      if (
        start &&
        end &&
        notebooks &&
        notebooks.length === 0 &&
        topics &&
        topics.length === 0
      ) {
        const data = await getNotesByDateRange(user?.uid, start, end)
        if (data) {
          setNotes(data)
        }
      }

      if (notebooks && notebooks.length > 0 && topics && topics.length > 0) {
        const data = await notebooksPageQueryBooksTopics(
          user?.uid,
          start,
          end,
          notebooks,
          topics
        )
        if (data) {
          setNotes(data)
        }
      }

      if (notebooks && notebooks.length > 0 && topics && topics.length === 0) {
        const data = await notebooksPageQueryBooksOnly(
          user?.uid,
          start,
          end,
          notebooks
        )
        if (data) {
          setNotes(data)
        }
      }

      if (notebooks && notebooks.length === 0 && topics && topics.length > 0) {
        const data = await notebooksPageQueryTopicsOnly(
          user?.uid,
          start,
          end,
          topics
        )
        if (data) {
          setNotes(data)
        }
      }
    },
    [setNotes, user]
  )

  const saveNote = async (
    note: TextNote,
    html: string,
    title: string,
    tags: string[],
    notebooks: string[]
  ) => {
    setNotesSaving(true)
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    const noteData: TextNote = {
      uid: user?.uid,
      note: note,
      html: html,
      associatedDate: d,
      createdDate: new Date(),
      lastUpdate: new Date(),
      tags: tags,
      notebooks: notebooks,
      title: title,
    }
    const dateCheck = await getDate(user?.uid, d)
    if (dateCheck.length > 0) {
      const data = await saveTextNote(noteData)
      if (data) {
        if (dateCheck[0]._id) {
          Promise.all([
            await handleNewTags(tags, data.id, ContentType.notes),
            await handleNewNotebooks(notebooks, data.id, ContentType.notes),
            await updateDateCount(user?.uid, dateCheck[0]._id, {
              _id: data.id,
              type: ContentType.notes,
            }),
          ])

          setNotesSaving(false)
          setTriggerDateUpdate(true)
        }
        return data
      }
    } else {
      const date = await createDate()
      const data = await saveTextNote(noteData)
      if (date && data) {
        const gd = await getDate(user?.uid, d)
        if (gd) {
          const date = gd[0]
          await setDateTargetAsync(date)
          if (date._id) {
            await updateDateCount(user?.uid, date._id, {
              _id: data.id,
              type: ContentType.notes,
            })
            Promise.all([
              await handleNewTags(tags, data.id, ContentType.notes),
              await handleNewNotebooks(notebooks, data.id, ContentType.notes),
            ])

            setNotesSaving(false)
            setTriggerDateUpdate(true)
          }
          return data
        }
      }
    }
  }

  // Setup tags library and post or update tag library
  const updateNote = async (
    note: TextNote,
    html: string,
    _id: string,
    title: string,
    tags: string[],
    notebooks: string[]
  ) => {
    setNotesSaving(true)
    const oldNote = notes.filter((note: TextNote) => note._id === _id)

    oldNote[0].notebooks ? oldNote[0].notebooks : (oldNote[0].notebooks = [])

    const tagDifference = oldNote[0].tags
      .filter((x: string) => !tags.includes(x))
      .concat(tags.filter((x) => !oldNote[0].tags.includes(x)))
    const newTags = tags
      .map((tag) => tagDifference.filter((x: string) => x === tag))
      .filter((x) => x.length === 1)
      .flat()
    const deleteTags = oldNote[0].tags
      .map((tag: string) => tagDifference.filter((x: string) => x === tag))
      .filter((x: string[]) => x.length === 1)
      .flat()
    const notebookDifference = oldNote[0].notebooks
      .filter((x: string) => !notebooks.includes(x))
      .concat(notebooks.filter((x) => !oldNote[0].notebooks.includes(x)))
    const newNotebooks = notebooks
      .map((notebook) =>
        notebookDifference.filter((x: string) => x === notebook)
      )
      .filter((x) => x.length === 1)
      .flat()
    const deleteNotebooks = oldNote[0].notebooks
      .map((notebook: string) =>
        notebookDifference.filter((x: string) => x === notebook)
      )
      .filter((x: string[]) => x.length === 1)
      .flat()

    const data = await updateTextNote(
      user?.uid,
      _id,
      note,
      html,
      title,
      tags,
      notebooks
    )

    if (data) {
      Promise.all([
        await handleUpdateTags(newTags, deleteTags, _id, ContentType.notes),
        await handleUpdateNotebooks(
          newNotebooks,
          deleteNotebooks,
          _id,
          ContentType.notes
        ),
      ])

      setNotesSaving(false)
    }
  }

  const getNote = async (_id: string) => {
    setNotesSaving(true)
    const data = await getTextNote(user?.uid, _id)
    if (data) {
      return data
    }
  }

  // remove date if no notes
  const deleteNote = async (_id: string) => {
    setNotesSaving(true)
    const targetNote: DocumentData[] | TextNote[] = notes.filter(
      (note: TextNote) => note._id === _id
    )
    const targetDate: DocumentData[] | DateEntry[] = dates.filter(
      (date: DateEntry) =>
        JSON.stringify(targetNote[0].associatedDate) ===
        JSON.stringify(date.date)
    )

    const data = await deleteTextNote(user?.uid, _id)
    if (data) {
      Promise.all([
        await handleDeleteTags(targetNote[0].tags, _id, ContentType.notes),
        await handleDeleteNotebooks(
          targetNote[0].notebooks,
          _id,
          ContentType.notes
        ),
      ])
      if (targetDate[0].contentCount.length === 1 && targetDate[0]._id) {
        await deleteDate(user?.uid, targetDate[0]._id)
        setNotesSaving(false)
        setTriggerDateUpdate(true)
      } else {
        await removeFromDate(user?.uid, targetDate[0]._id, targetNote[0]._id)
        setNotesSaving(false)
        setTriggerDateUpdate(true)
      }
    }
  }

  return {
    notesLoading,
    notesSaving,
    notes,
    getNotesByDates,
    getNotesDesc,
    getNotesQuery,
    saveNote,
    updateNote,
    getNote,
    deleteNote,
  } as const
}
