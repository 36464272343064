import React, { useEffect, useState } from "react"
import {
  NotebookItemWrapper,
  NoteBooksHeaderWrapper,
  NoteBooksAddButton,
  NoteBookAddButtonWrapper,
  NoteBookInputWrapper,
} from "./Notebooks.vanilla.css"
import { useNotebooks } from "../../../hooks"
import { Button } from "../../../components"
import { AddNewNotebookInput } from "./Styles"

export const Notebooks = () => {
  const [title, setTitle] = useState<string>("")
  const { notebooks, fetchNotebooks, addNotebook } = useNotebooks()

  useEffect(() => {
    fetchNotebooks()
  }, [])

  const AddNewNotebook = async () => {
    if (title !== "") {
      const data = await addNotebook(title)
      if (data) {
        setTitle("")
        fetchNotebooks()
      }
    }
  }

  return (
    <div>
      <div>
        <h2>Notebooks</h2>
        <h4>Active Notebooks</h4>
        <div className={NoteBooksHeaderWrapper}>
          <div className={NoteBookInputWrapper}>
            <AddNewNotebookInput
              placeholder="New Notebook Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className={NoteBookAddButtonWrapper}>
            <Button
              className={NoteBooksAddButton}
              onClick={() => AddNewNotebook()}
            >
              + Add Notebook
            </Button>
          </div>
        </div>
        <div>
          {notebooks.map((n) => (
            <div className={NotebookItemWrapper} key={n._id}>
              <div>{n.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
