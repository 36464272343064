import zod, {
  object,
  string,
  date,
  array,
  Infer,
  number,
  undefined,
  unknown,
} from "myzod"

export const TimeStamp = object({
  seconds: number(),
  nanoseconds: number(),
})

export enum ContentType {
  notes = "notes",
}

export const ContentCountSchema = object({
  _id: string(),
  type: zod.enum(ContentType),
})

export const DateEntrySchema = object({
  _id: string().or(undefined()),
  uid: string(),
  date: date(),
  contentCount: array(ContentCountSchema),
})

export const TextNoteSchema = object({
  _id: string().or(undefined()),
  uid: string(),
  note: unknown(),
  html: string(),
  associatedDate: date(),
  createdDate: date(),
  lastUpdate: date(),
  tags: array(string()),
  notebooks: array(string()),
  title: string(),
})

export const TagSchema = object({
  _id: string().or(undefined()),
  title: string(),
  contentCount: array(ContentCountSchema),
})

export const NoteBookSchema = object({
  _id: string(),
  title: string(),
  createdDate: date(),
  contentCount: array(ContentCountSchema),
})

export type DateEntry = Infer<typeof DateEntrySchema>
export type TextNote = Infer<typeof TextNoteSchema>
export type ContentCount = Infer<typeof ContentCountSchema>
export type Tag = Infer<typeof TagSchema>
export type NoteBook = Infer<typeof NoteBookSchema>
