import {
  collection,
  where,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore"
import { db } from "../../firebase"

export const newDocument = async <T>(data: T, uid: string, col: string) => {
  try {
    const res = await addDoc(collection(db, `users/${uid}/${col}`), data)
    return res
  } catch (error) {
    console.log(error)
  }
}

export const addContentCount = async <T>(
  data: T,
  uid: string,
  col: string,
  _id: string
) => {
  try {
    const datesRef = doc(db, `users/${uid}/${col}`, _id)
    await updateDoc(datesRef, {
      contentCount: arrayUnion(data),
    })
    return "update complete"
  } catch (error) {
    console.log(error)
  }
}

export const removeContentCount = async <T>(
  data: T,
  uid: string,
  col: string,
  _id: string
) => {
  try {
    const tagsRef = doc(db, `users/${uid}/${col}`, _id)
    await updateDoc(tagsRef, {
      contentCount: arrayRemove(data),
    })
  } catch (error) {
    console.log(error)
  }
}

export const delDoc = async (uid: string, col: string, _id: string) => {
  try {
    const res = await deleteDoc(doc(db, `users/${uid}/${col}`, _id))
    return { res, update: "completed" }
  } catch (error) {
    console.log(error)
  }
}

export const fetchAllDoc = async <T>(uid: string, col: string) => {
  try {
    const datesRef = collection(db, `users/${uid}/${col}`)
    const q = query(datesRef)

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc: any) => {
      const data: T = {
        _id: doc.id,
        ...doc.data(),
      }

      return data
    })
  } catch (error) {
    console.log(error)
  }
}

export const fetchDocByTitle = async <T>(
  uid: string,
  col: string,
  title: string
) => {
  try {
    const ref = collection(db, `users/${uid}/${col}`)
    const q = query(ref, where("title", "==", title))

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc: any) => {
      const data: T = {
        _id: doc.id,
        ...doc.data(),
      }

      return data
    })
  } catch (error) {
    console.log(error)
  }
}
