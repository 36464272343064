import React, { useEffect, useState } from "react"
import Select from "react-select"
import { MultiValue } from "react-select/dist/declarations/src/types"
import { NoteBook, Tag } from "../../../../schema"
import Calendar from "react-date-picker"
import { CalendarWrapper, CustomSelectWraper } from "./Styles"
import { Icons } from "../../../../components"

import { useDates, useUsers, useNotebooks, useTags } from "../../../../hooks"
import {
  ContentControlsContainer,
  ContentControlsWrapper,
  FilterText,
  FilterTextLabel,
  ResetText,
  ControlLabel,
  ControlDatesWrapper,
  ContentControlsContainerFiltersActive,
} from "./ContentControls.vanilla.css"

interface SelectOption {
  value: string
  label: string
}

interface ContentControlsProps {
  start: Date
  end: Date
  notebooksList: MultiValue<SelectOption>
  tagsList: MultiValue<SelectOption>
  defaultStartDate: Date
  isFilteredUpdateStatus: (val: boolean) => void
  onChangeStart: (val: Date) => void
  onChangeEnd: (val: Date) => void
  setNotebooksList: (val: MultiValue<SelectOption>) => void
  setTagsList: (val: MultiValue<SelectOption>) => void
}

export const ContentControls = ({
  start,
  end,
  notebooksList,
  tagsList,
  defaultStartDate,
  isFilteredUpdateStatus,
  onChangeStart,
  onChangeEnd,
  setNotebooksList,
  setTagsList,
}: ContentControlsProps) => {
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const { user } = useUsers()
  const { notebooks } = useNotebooks()
  const { tags } = useTags()
  const { fetchDates } = useDates()

  const max = new Date()
  const min = new Date(user.metadata.creationTime)

  const startMax = max.getTime() >= end.getTime() ? end : max
  const endMin = start.getTime() >= min.getTime() ? start : min

  useEffect(() => {
    if (
      start !== defaultStartDate ||
      end.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0) ||
      notebooksList.length > 0 ||
      tagsList.length > 0
    ) {
      setIsFiltered(true)
      isFilteredUpdateStatus(true)
    } else {
      setIsFiltered(false)
      isFilteredUpdateStatus(false)
    }
  }, [start, end, notebooksList, tagsList])

  const notebookSelectValues = notebooks.map((item) => {
    if (item._id) {
      return { value: item._id, label: item.title }
    }
  }) as SelectOption[]

  const tagbookSelectValues = tags.map((item: Tag) => {
    return { value: item._id, label: item.title }
  }) as SelectOption[]

  const reset = () => {
    onChangeStart(defaultStartDate)
    onChangeEnd(new Date())
    setNotebooksList([])
    setTagsList([])
    fetchDates()
  }

  return (
    <div
      className={
        isFiltered
          ? ContentControlsContainerFiltersActive
          : ContentControlsContainer
      }
    >
      <div className={ContentControlsWrapper}>
        <div className={FilterText}>
          <span className={FilterTextLabel}>Filters</span>
        </div>
        <div className={ControlDatesWrapper}>
          <CalendarWrapper>
            <div className={ControlLabel}>From</div>
            <Calendar
              onChange={onChangeStart}
              value={start}
              defaultValue={defaultStartDate}
              maxDate={startMax}
              minDate={min}
              prevLabel={<Icons.ChevronLeft />}
              nextLabel={<Icons.ChevronRight />}
              prev2Label={<Icons.ChevronsLeft />}
              next2Label={<Icons.ChevronsRight />}
              calendarIcon={<Icons.Calendar />}
            />
          </CalendarWrapper>
          <CalendarWrapper>
            <div className={ControlLabel}>To</div>
            <Calendar
              onChange={onChangeEnd}
              value={end}
              defaultValue={max}
              maxDate={max}
              minDate={endMin}
              prevLabel={<Icons.ChevronLeft />}
              nextLabel={<Icons.ChevronRight />}
              prev2Label={<Icons.ChevronsLeft />}
              next2Label={<Icons.ChevronsRight />}
              calendarIcon={<Icons.Calendar />}
            />
          </CalendarWrapper>
        </div>
        <div>
          <CustomSelectWraper>
            <Select
              isMulti
              name="notebooks"
              options={notebookSelectValues}
              isClearable
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Notebooks"
              value={notebooksList}
              onChange={(newValue: MultiValue<SelectOption>) =>
                setNotebooksList(newValue)
              }
            />
          </CustomSelectWraper>
        </div>
        <div>
          <CustomSelectWraper>
            <Select
              isMulti
              name="tags"
              options={tagbookSelectValues}
              isClearable
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Tags"
              value={tagsList}
              onChange={(newValue: MultiValue<SelectOption>) =>
                setTagsList(newValue)
              }
            />
          </CustomSelectWraper>
        </div>
        <div className={ResetText} onClick={() => reset()}>
          Reset
        </div>
      </div>
    </div>
  )
}
