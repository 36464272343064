import React from "react"
import { Transition } from "react-transition-group"
import {
  ModalContainer,
  ModalCloseWrapper,
  ChildWrapper,
} from "./Modal.vanilla.css"

export interface ModalProps {
  active: boolean
  children: JSX.Element
  toggle: (arg: boolean) => void
}

const duration = 250

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles: any = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

export const Modal = ({ active, children, toggle }: ModalProps) => {
  return (
    <Transition in={active} timeout={duration} mountOnEnter unmountOnExit>
      {(state) => (
        <div
          className={ModalContainer}
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          <div onClick={() => toggle(!active)} className={ModalCloseWrapper} />
          <div className={ChildWrapper}>{children}</div>
        </div>
      )}
    </Transition>
  )
}
