import React from "react"
import {
  usePlateEditorRef,
  BlockToolbarButton,
  MarkToolbarButton,
  ListToolbarButton,
  LinkToolbarButton,
  getPluginType,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_UL,
  ELEMENT_OL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_STRIKETHROUGH,
} from "@udecode/plate"
import { HASHTAG } from "../TextNoteEditor/hashTagPlugin"
import { CustomToolBar, ToolBarSection } from "./Styles"
import { Icons } from "../../components"

export const TextNoteEditToolbar = () => {
  const editor = usePlateEditorRef()
  return (
    <CustomToolBar>
      <ToolBarSection>
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H1)}
          icon={<>H1</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H2)}
          icon={<>H2</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H3)}
          icon={<>H3</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H4)}
          icon={<>H4</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
      </ToolBarSection>
      <ToolBarSection>
        <MarkToolbarButton
          type={getPluginType(editor, MARK_BOLD)}
          icon={<>B</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
        <MarkToolbarButton
          type={getPluginType(editor, MARK_ITALIC)}
          icon={<>I</>}
          // tooltip={{ content: "Italic (⌘I)", ...tooltip }}
        />
        <MarkToolbarButton
          type={getPluginType(editor, MARK_UNDERLINE)}
          icon={<>U</>}
          // tooltip={{ content: "Underline (⌘U)", ...tooltip }}
        />
        <MarkToolbarButton
          type={getPluginType(editor, MARK_STRIKETHROUGH)}
          icon={<s>S</s>}
          // tooltip={{ content: "Underline (⌘U)", ...tooltip }}
        />
        <LinkToolbarButton icon={<Icons.Link />} />
      </ToolBarSection>
      <ToolBarSection>
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_UL)}
          icon={<Icons.UnorderedList />}
        />
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_OL)}
          icon={<Icons.OrderedList />}
        />
      </ToolBarSection>
      <ToolBarSection>
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
          icon={<>BQ</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_CODE_BLOCK)}
          icon={<>CB</>}
          // tooltip={{ content: "Bold (⌘B)", ...tooltip }}
        />
      </ToolBarSection>
      <ToolBarSection>
        <MarkToolbarButton
          type={getPluginType(editor, HASHTAG)}
          icon={<>TAG</>}
        />
      </ToolBarSection>
    </CustomToolBar>
  )
}
