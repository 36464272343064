import styled from "styled-components"

export const AddNewNotebookInput = styled.input`
  width: 100%;
  background: ${(props) => props.theme.colors.black.l3};
  padding: ${(props) => props.theme.spacing.x2};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.theme.spacing.x3};
  letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
  color: ${(props) => props.theme.colors.white.l2};
  border: none;
  border-radius: ${(props) => props.theme.spacing.x1};

  &::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => props.theme.spacing.x3};
    letter-spacing: ${(props) => props.theme.typography.letterSpacing_10};
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.spacing.x4};
    }
  }

  &:focus-visible {
    /* border-bottom: 1px solid ${(props) => props.theme.colors.purple.l2}; */
    outline: none;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: ${(props) => props.theme.colors.purple.l2};
    }
  }

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.spacing.x4};
    padding: ${(props) => props.theme.spacing.x2}
      ${(props) => props.theme.spacing.x4};
  }
`
