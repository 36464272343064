import React from "react"
import {
  BalloonToolbar,
  usePlateEditorRef,
  MarkToolbarButton,
  getPluginType,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
} from "@udecode/plate"

export const InlineToolbar = () => {
  const editor = usePlateEditorRef()

  const arrow = false
  const theme = "dark"

  return (
    <BalloonToolbar theme={theme} arrow={arrow}>
      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<>B</>}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<>I</>}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<>U</>}
      />
    </BalloonToolbar>
  )
}
