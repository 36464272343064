import {
  collection,
  where,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore"
import { db } from "../../firebase"
import { ContentType } from "../../types"
import { Tag, TagSchema } from "../../schema"

export const getAllTags = async (uid: string) => {
  try {
    const datesRef = collection(db, `users/${uid}/tags`)
    const q = query(datesRef)

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc: any) => {
      const data = {
        _id: doc.id,
        title: doc.data().title,
        contentCount: doc.data().contentCount,
      }

      return TagSchema.parse(data)
    })
  } catch (error) {
    console.log(error)
  }
}

export const getTag = async (uid: string, tag: string) => {
  try {
    const datesRef = collection(db, `users/${uid}/tags`)
    const q = query(datesRef, where("title", "==", tag))

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc: any) => {
      const data: Tag = {
        _id: doc.id,
        ...doc.data(),
      }

      return data
    })
  } catch (error) {
    console.log(error)
  }
}

export const addTag = async (
  uid: string,
  tag: string,
  noteID: string,
  type: ContentType
) => {
  try {
    const res = await addDoc(collection(db, `users/${uid}/tags`), {
      title: tag,
      contentCount: [
        {
          _id: noteID,
          type,
        },
      ],
    })
    return res
  } catch (error) {
    console.log(error)
  }
}

export const updateTag = async (
  uid: string,
  _id: string, // tag id
  noteID: string,
  type: ContentType
) => {
  try {
    const datesRef = doc(db, `users/${uid}/tags`, _id)
    const entry = {
      _id: noteID,
      type,
    }
    await updateDoc(datesRef, {
      contentCount: arrayUnion(entry),
    })
    return "update complete"
  } catch (error) {
    console.log(error)
  }
}

export const removeContentFromTag = async (
  uid: string,
  _id: string,
  noteID: string,
  type: ContentType
) => {
  try {
    const tagsRef = doc(db, `users/${uid}/tags`, _id)
    await updateDoc(tagsRef, {
      contentCount: arrayRemove({ _id: noteID, type }),
    })
  } catch (error) {
    console.log(error)
  }
}

export const deleteTag = async (uid: string, tag: string) => {
  try {
    const res = await deleteDoc(doc(db, `users/${uid}/tags`, tag))
    return { res, update: "completed" }
  } catch (error) {
    console.log(error)
  }
}
