import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../firebase/firebase"
import {
  registerWithEmailAndPassword,
  signInWithGithub,
  signInWithGoogle,
  signInWithFacebook,
  signInWithTwitter,
} from "../../firebase/auth"
import { Navigate } from "react-router-dom"

import {
  LoginPage,
  LoginIntro,
  LoginTitle,
  LoginIntroText,
  LoginInputFields,
  LoginInputContainer,
  LoginButton,
  LoginWithServiceButton,
  LoginIconWrapper,
  ResetRegisterContainer,
  LinkContainer,
  StyledLink,
} from "../Login/style"
import { ButtonText, Icons } from "../../components"

export const SignUp = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [user] = useAuthState(auth)
  const register = () => {
    if (!name) alert("Please enter name")
    registerWithEmailAndPassword(name, email, password)
  }
  if (user) {
    return <Navigate to="/" replace />
  } else {
    return (
      <LoginPage>
        <LoginIntro>
          <LoginTitle>Work Diary</LoginTitle>
          <LoginIntroText>
            A notes app to record and contextualise your life at work.
          </LoginIntroText>
        </LoginIntro>
        <LoginInputContainer>
          <LoginInputFields
            type="text"
            className="register__textBox"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
          />
          <LoginInputFields
            type="text"
            className="register__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <LoginInputFields
            type="password"
            className="register__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <LoginButton className="register__btn" onClick={register}>
            <ButtonText>Sign up</ButtonText>
          </LoginButton>
          <LoginWithServiceButton
            className="register__btn register__google"
            onClick={signInWithGoogle}
          >
            <LoginIconWrapper>
              <Icons.Google />
            </LoginIconWrapper>
            <ButtonText>Sign up with Google</ButtonText>
          </LoginWithServiceButton>
          <LoginWithServiceButton
            className="register__btn register__github"
            onClick={signInWithGithub}
          >
            <LoginIconWrapper>
              <Icons.Github />
            </LoginIconWrapper>
            <ButtonText>Sign up with Github</ButtonText>
          </LoginWithServiceButton>
          <LoginWithServiceButton
            className="register__btn register__facebook"
            onClick={signInWithFacebook}
          >
            <LoginIconWrapper>
              <Icons.Facebook />
            </LoginIconWrapper>
            <ButtonText>Sign up with Facebook</ButtonText>
          </LoginWithServiceButton>
          <LoginWithServiceButton
            className="register__btn register__twitter"
            onClick={signInWithTwitter}
          >
            <LoginIconWrapper>
              <Icons.Twitter />
            </LoginIconWrapper>
            <ButtonText>Sign up with Twitter</ButtonText>
          </LoginWithServiceButton>
          <ResetRegisterContainer>
            <LinkContainer>
              Already have an account? <StyledLink to="/">Login</StyledLink>{" "}
              now.
            </LinkContainer>
          </ResetRegisterContainer>
        </LoginInputContainer>
      </LoginPage>
    )
  }
}
