import { doc, setDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { User, Tiers } from "../../types"

export const addUser = async (user: User) => {
  await setDoc(doc(db, "users", user.uid), {
    uid: user.uid,
    name: user.name,
    authProvider: user.authProvider,
    email: user.email,
    tier: Tiers.Free,
  })
}
