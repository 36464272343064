import React from "react"

export const LogOut = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 10V8H5V5L0 9L5 13V10H14Z" fill="white" />
    <path
      d="M19 0H10C8.897 0 8 0.897 8 2V6H10V2H19V16H10V12H8V16C8 17.103 8.897 18 10 18H19C20.103 18 21 17.103 21 16V2C21 0.897 20.103 0 19 0Z"
      fill="white"
    />
  </svg>
)
