import {
  Plate,
  createPlateUI,
  createPlugins,
  createParagraphPlugin,
  createBlockquotePlugin,
  createCodeBlockPlugin,
  createHeadingPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createStrikethroughPlugin,
  createCodePlugin,
  createLinkPlugin,
  createKbdPlugin,
  createSoftBreakPlugin,
  createIndentPlugin,
  createListPlugin,
  createResetNodePlugin,
  ELEMENT_CODE_BLOCK,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_TD,
  ELEMENT_PARAGRAPH,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
} from "@udecode/plate"
import { createHashtagPlugin, HASHTAG } from "./hashTagPlugin"

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, HASHTAG],
  defaultType: ELEMENT_PARAGRAPH,
}

const plugins = createPlugins(
  [
    createParagraphPlugin(),
    createBlockquotePlugin(),
    createCodeBlockPlugin(),
    createHeadingPlugin(),
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),
    createStrikethroughPlugin(),
    createLinkPlugin(),
    createCodePlugin(),
    createListPlugin(),
    createKbdPlugin(),
    createIndentPlugin({
      inject: {
        props: {
          validTypes: [ELEMENT_PARAGRAPH],
        },
      },
    }),
    createSoftBreakPlugin({
      options: {
        rules: [
          { hotkey: "shift+enter" },
          {
            hotkey: "enter",
            query: {
              allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
            },
          },
        ],
      },
    }),
    createIndentPlugin({
      inject: {
        props: {
          validTypes: [ELEMENT_PARAGRAPH],
        },
      },
    }),
    createResetNodePlugin({
      options: {
        rules: [
          {
            ...resetBlockTypesCommonRule,
            hotkey: "Enter",
            predicate: isBlockAboveEmpty,
          },
          {
            ...resetBlockTypesCommonRule,
            hotkey: "Backspace",
            predicate: isSelectionAtBlockStart,
          },
        ],
      },
    }),
    createHashtagPlugin(),
  ],
  {
    components: createPlateUI(),
  }
)

export default plugins
