import { collection, query, getDocs, where } from "firebase/firestore"
import { db } from "../../firebase"
import { User } from "../../types"

export const searchUser = async (userID: string) => {
  const q = query(collection(db, "users"), where("uid", "==", userID))
  const docs = await getDocs(q)
  return docs
}

export const getUserData = async (userID: string) => {
  const q = query(collection(db, "users"), where("uid", "==", userID))
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc: any) => {
    const data: User = {
      _id: doc.id,
      ...doc.data(),
    }

    return data
  })
}
