import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from "../firebase"

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}
