import React, { useEffect, useState } from "react"
import { useMediaQuery, useIsFirstRender } from "usehooks-ts"
import { MultiValue } from "react-select/dist/declarations/src/types"
import { DateBlock, ContentControls } from "./components"
// import { useSearchParams } from "react-router-dom"
import {
  DatesContainer,
  DatesDashMark,
  DatesDashMarkToday,
  DayContainer,
  DatesEndCircle,
  NotesContainer,
  NotesWrapper,
  NoteWrapper,
  NotesDashMarkHor,
  MobileDashMarkDate,
  MobileDashMarkNote,
} from "./Diary.vanilla.css"
import { NoNotes, TextNoteView, AddNewMenu } from "../../components"
import { DateEntry, TextNote } from "../../schema"
import { useDates, useNotes, useNotebooks, useTags } from "../../hooks"

interface SelectOption {
  value: string
  label: string
}

export const Diary = () => {
  const { dates, dateTarget, fetchDates } = useDates()
  const { notes, getNotesQuery } = useNotes()
  const { fetchNotebooks } = useNotebooks()
  const { getTags } = useTags()
  // const { editor, editorControl } = useEditorControl()
  const matches = useMediaQuery("(max-width: 768px)")
  // const [showEditor, setShowEditor] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [defValSet, setDefValSet] = useState<number>(0)
  const [defStartDate, setDefStartDate] = useState<Date>(new Date())
  const [start, setStart] = useState<Date>(new Date())
  const [end, setEnd] = useState<Date>(new Date())
  const [notebooksList, setNotebooksList] = useState<MultiValue<SelectOption>>(
    []
  )
  const [tagsList, setTagsList] = useState<MultiValue<SelectOption>>([])
  const firstRender = useIsFirstRender()

  useEffect(() => {
    if (!firstRender) {
      const notebooksArray = notebooksList.map((item) => item.label)
      const tagsArray = tagsList.map((item) => item.label)
      getNotesQuery(start, end, notebooksArray, tagsArray)
    }
  }, [start, end, notebooksList, tagsList, firstRender, getNotesQuery])

  useEffect(() => {
    if (dates.length !== 0 && defValSet === 0) {
      setDefValSet(1)
      setDefStartDate(dates[dates.length - 1].date)
      setStart(dates[dates.length - 1].date)
    }
  }, [dates, defValSet, defStartDate])

  //  this doesn't work with the filtering on, resets to the default
  // This will get refactored when we move to creating/editing in their own page

  useEffect(() => {
    if (!isFiltered) {
      // editorControl(true, false)
      // setShowEditor(false)
      fetchDates()
      fetchNotebooks()
      getTags()
    }
  }, [])

  const noNotesToday = () => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    const notesList = notes.filter(
      (note: TextNote) =>
        JSON.stringify(note.associatedDate) === JSON.stringify(d)
    )
    return notesList.length === 0
  }

  const updateIsfiltered = (status: boolean) => {
    setIsFiltered(status)
  }

  if (dates.length > 0 && dateTarget && defStartDate) {
    return (
      <div>
        <ContentControls
          start={start}
          end={end}
          notebooksList={notebooksList}
          tagsList={tagsList}
          defaultStartDate={defStartDate}
          isFilteredUpdateStatus={updateIsfiltered}
          onChangeStart={setStart}
          onChangeEnd={setEnd}
          setNotebooksList={setNotebooksList}
          setTagsList={setTagsList}
        />
        {dates.map((date: DateEntry, index_date: number) => {
          if (
            notes.filter(
              (note: TextNote) =>
                JSON.stringify(note.associatedDate) ===
                JSON.stringify(date.date)
            ).length === 0 &&
            date._id
          ) {
            return
          }
          return (
            <div className={DayContainer} key={JSON.stringify(date.date)}>
              <div className={DatesContainer}>
                <DateBlock
                  index={index_date}
                  date={date.date}
                  notesCount={date.contentCount.length}
                />
                {matches && <div className={MobileDashMarkDate} />}
                {notes.filter(
                  (note: TextNote) =>
                    JSON.stringify(note.associatedDate) ===
                    JSON.stringify(date.date)
                ).length > 0 && (
                  <>
                    {!matches && (
                      <div
                        className={
                          index_date === 0 ? DatesDashMarkToday : DatesDashMark
                        }
                      >
                        <div className={DatesEndCircle} />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={NotesContainer}>
                <div className={NotesWrapper}>
                  {!matches && index_date === 0 && <AddNewMenu offset />}

                  {noNotesToday() && index_date === 0 && (
                    <NoNotes
                      offset={matches ? 0 : 100}
                      title="No Notes Today"
                      message="Click the add button above to add your first note for the day"
                    />
                  )}
                  {notes
                    .filter(
                      (note: TextNote) =>
                        JSON.stringify(note.associatedDate) ===
                        JSON.stringify(date.date)
                    )
                    .map((note: TextNote) => {
                      if (note && note._id) {
                        return (
                          <>
                            <div className={NoteWrapper} key={note._id}>
                              {!matches && <div className={NotesDashMarkHor} />}
                              <TextNoteView
                                note={note.note}
                                cachedHTML={note.html}
                                title={note.title}
                                notebooks={note.notebooks}
                                createdDate={note.createdDate}
                                _id={note._id}
                                key={note._id}
                                selectedNoteBooks={note.notebooks}
                              />
                            </div>
                            {matches && <div className={MobileDashMarkNote} />}
                          </>
                        )
                      }
                    })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  {
    return <></>
  }
}
