import React from "react"
import { Notebooks } from "./Notebooks"
import { SettingsContainer, SettingsWrapper } from "./Settings.vanilla.css"

export const Settings = () => {
  return (
    <div className={SettingsContainer}>
      <div className={SettingsWrapper}>
        <h1>Settings</h1>
      </div>
      <div className={SettingsWrapper}>
        <div>
          <Notebooks />
        </div>
      </div>
    </div>
  )
}
