import "workdiary/src/components/TextNoteView/TextNoteView.vanilla.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"source\":\"#H4sIAAAAAAAAE81VzY7bIBi871MgRZXagyP/x/Zeut06575BRQA7qBgQ4MbbKu9ecOysnbjdapuqvdjk8wAz8w1h/TmQnam09MH3OwAw1ZLBpwJUjHT3tuDeHqaKIEMFLwASrG34+KUAgRseKDZ7O/b9N+6nhBhTXttCKjsQhcPDfdpB9KVWouW4AKswiZLowZXtqkLZSrkp8zLvgUJhojwFMW11AWI3/Xi3HtkGPdsGqppybyeMEU0BsgtQ2IMqwY1XwYYyK+uTkJJyfT/WtXlipABcqAayc/VAaL03dlfff0bSbxY46mDEGEtPS4h6pf46IM2SkgmdaImzs2iGim9P+rTHL0gb0hnPKMh1ZdcsQCslUQhq8oKi5Pcyo8RhNi1dngYZrblHDWlswxHhluvPknARpK2fZ+HjLHrxJHSD5erkTziuIDpP7yEWB+uERbuYulluvPI/BtswPnuDCRIKntRwwclMzuZPO5Zed+ylmL22Y9kkgycrgD/zLRsrg+1GSEvHFrVgFINV9CHO43ICOAf5GjPZN39dUuCtkxI+PmzL7F8lBRZ78ZWoXhRqlXZdkoKe+E9wu1vLTuI0SJO/IXvKekEdJhVsmZnh0P96Xp41vW8IphBopAjhAHIM3jaw84Z7bpPaU/KuV7GeX58XB6m//8Z/3+MUHg7wiYbR9hkuvsaNWme4bAF3Xu/4A/w9m7LlBwAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var TextViewNoteBooks = '_1pxtfsp5';
export var TextViewNoteBooksButton = '_1pxtfsp6';
export var TextViewNoteBooksButtonText = '_1pxtfsp7';
export var TextViewNoteButtonContainer = '_1pxtfsp9';
export var TextViewNoteDate = '_1pxtfsp3';
export var TextViewNoteDateText = '_1pxtfsp4';
export var TextViewNoteEditButton = '_1pxtfspa';
export var TextViewNoteEditButtonDisabled = '_1pxtfspb';
export var TextViewNoteEditButtonText = '_1pxtfspc';
export var TextViewNoteText = '_1pxtfsp8';
export var TextViewNoteTitle = '_1pxtfsp1';
export var TextViewNoteTitleText = '_1pxtfsp2';
export var TextViewWrapper = '_1pxtfsp0';