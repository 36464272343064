import React, { useEffect, useState } from "react"
import {
  Plate,
  serializeHtml,
  createPlateEditor,
  createPlateUI,
} from "@udecode/plate"
import { useNavigate } from "react-router-dom"
import flatten from "flat"
import Select from "react-select"
import { MultiValue } from "react-select/dist/declarations/src/types"
import { useNotebooks, useNotes } from "../../hooks"
import plugins from "./plugins"
import {
  TextNoteEditorContainer,
  SlateWrapper,
  TitleWrapper,
  TextNotesEditorInputTitle,
  SlateTextEditorWrapper,
  NoteBooksWrapper,
  NoteBooksAdd,
  NoteBooksAddText,
  RedButtonNoMargin,
  ButtonGroupLeft,
  ButtonGroupRight,
  SavingWrapper,
  SavingWrapperText,
} from "./Styles"
import { TextNotesEditorWrapper } from "./TextNoteEditor.vanilla.css"
import {
  ButtonText,
  MultiButtonContainer,
  BlueButton,
  RedButton,
  GreenButton,
  GreyButton,
  SelectWrapper,
} from ".."
import { InlineToolbar, TextNoteEditToolbar } from "../"
import { NoteBook } from "../../types"
import { Icons } from "../Icons"

interface TextNoteEditorProps {
  note?: any
  title?: string
  deleteNote?: (_id: string) => void
  _id?: string
  selectedNoteBooks?: string[]
}

interface SelectOption {
  value: string
  label: string
}

const initialValue = [
  {
    type: "p",
    children: [
      {
        text: "",
      },
    ],
  },
]

export const TextNoteEditor = ({
  note,
  title,
  deleteNote,
  _id,
  selectedNoteBooks,
}: TextNoteEditorProps) => {
  const [noteTitle, setNoteTitle] = useState<string>("")
  const [value, setValue] = useState<any>({})
  // const [html, setHTML] = useState<string>("")
  const [tagsList, setTagsList] = useState<string[]>([])
  const [notebooksList, setNotebooksList] = useState<MultiValue<SelectOption>>(
    []
  )
  const [showNoteBooks, setShowNoteBooks] = useState<boolean>(false)
  const [savingNote, setSavingNote] = useState<boolean>(false)
  const [deleteWarning, setDeleteWarning] = useState<boolean>(false)
  const { notebooks, fetchNotebooks } = useNotebooks()
  const { saveNote, updateNote } = useNotes()
  const navigate = useNavigate()

  const notebookSelectValues = notebooks.map((item: NoteBook) => {
    if (item._id) {
      return { value: item._id, label: item.title }
    }
  }) as SelectOption[]

  useEffect(() => {
    if (note) {
      setValue(note)
    }
    if (title) {
      setNoteTitle(title)
    }
    if (notebooks && selectedNoteBooks && selectedNoteBooks.length > 0) {
      const setDefault = selectedNoteBooks.map(
        (item) => notebookSelectValues.filter((n) => n.label === item)[0]
      )
      setNotebooksList(setDefault)
      setShowNoteBooks(true)
    }
  }, [note, title, selectedNoteBooks])

  useEffect(() => {
    const flat: any = flatten(value)
    const tagValues: string[] = []

    for (const key in flat) {
      if (key.substring(key.length - 7) === "hashtag") {
        const tagPath = key.slice(0, -7) + "text"
        tagValues.push(flat[tagPath])
      }
    }
    const uniqueTags = [...new Set(tagValues)]
    setTagsList(uniqueTags)
  }, [value])

  const editableProps = {
    placeholder: "Type…",
    style: {
      padding: "16px",
    },
  }

  const parseHtml = () => {
    try {
      const html = serializeHtml(
        createPlateEditor({ plugins, components: createPlateUI() }),
        { nodes: value }
      )
      return html
    } catch (error) {
      console.log("ERROR DETAILS", error, note)
      return ""
    }
  }

  const saveNoteData = async () => {
    try {
      setSavingNote(true)
      const html = parseHtml()
      if (_id) {
        await updateNote(
          value,
          html,
          _id,
          noteTitle,
          tagsList,
          notebooksList.map((item: SelectOption) => item.label)
        )
        setSavingNote(false)
        navigate("/")
      } else {
        await saveNote(
          value,
          html,
          noteTitle,
          tagsList,
          notebooksList.map((item: SelectOption) => item.label)
        )
        setSavingNote(false)
        navigate("/")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <TextNoteEditorContainer>
      <div className={TextNotesEditorWrapper}>
        <TitleWrapper>
          <TextNotesEditorInputTitle
            onChange={(e) => setNoteTitle(e.target.value)}
            value={noteTitle}
            placeholder="Title"
          />
        </TitleWrapper>
        <NoteBooksWrapper>
          {showNoteBooks ? (
            <SelectWrapper>
              <Select
                isMulti
                name="notebooks"
                options={notebookSelectValues}
                isClearable
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Notebooks"
                value={notebooksList}
                onChange={(newValue: MultiValue<SelectOption>) =>
                  setNotebooksList(newValue)
                }
              />
            </SelectWrapper>
          ) : (
            <NoteBooksAdd>
              <NoteBooksAddText onClick={() => setShowNoteBooks(true)}>
                + Add to Notebooks
              </NoteBooksAddText>
            </NoteBooksAdd>
          )}
        </NoteBooksWrapper>
        <SlateTextEditorWrapper>
          <SlateWrapper>
            <Plate
              id="NoteEditor"
              data-gramm="true"
              editableProps={editableProps}
              onChange={(newValue) => {
                setValue(newValue)
              }}
              initialValue={note ? note : initialValue}
              plugins={plugins}
            >
              <TextNoteEditToolbar />
              <InlineToolbar />
            </Plate>
          </SlateWrapper>
        </SlateTextEditorWrapper>
        <MultiButtonContainer>
          {!deleteWarning && (
            <>
              {savingNote ? (
                <SavingWrapper>
                  <Icons.Save />
                  <SavingWrapperText>Saving...</SavingWrapperText>
                </SavingWrapper>
              ) : (
                <>
                  <ButtonGroupLeft>
                    {Object.keys(value).length === 0 ? (
                      <GreyButton>
                        <ButtonText>Save</ButtonText>
                      </GreyButton>
                    ) : (
                      <GreenButton onClick={() => saveNoteData()}>
                        <ButtonText>Save</ButtonText>
                      </GreenButton>
                    )}
                    {/* Needs some action here on cancel */}
                    <BlueButton>
                      <ButtonText onClick={() => navigate(-1)}>
                        Cancel
                      </ButtonText>
                    </BlueButton>
                  </ButtonGroupLeft>
                  {_id && (
                    <ButtonGroupRight>
                      <RedButtonNoMargin onClick={() => setDeleteWarning(true)}>
                        <ButtonText>Delete</ButtonText>
                      </RedButtonNoMargin>
                    </ButtonGroupRight>
                  )}
                </>
              )}
            </>
          )}
          {deleteWarning && (
            <ButtonGroupLeft>
              <RedButton
                onClick={() => (deleteNote && _id ? deleteNote(_id) : null)}
              >
                <ButtonText>Yes</ButtonText>
              </RedButton>
              <BlueButton onClick={() => setDeleteWarning(false)}>
                <ButtonText>Cancel</ButtonText>
              </BlueButton>
            </ButtonGroupLeft>
          )}
        </MultiButtonContainer>
      </div>
    </TextNoteEditorContainer>
  )
}
