export const firebaseTestConfig = {
  apiKey: "AIzaSyDTrQPSEu4aWBl6R_5_6qkr4wfDhY6TkJ4",
  authDomain: "work-diary-testing.firebaseapp.com",
  projectId: "work-diary-testing",
  storageBucket: "work-diary-testing.appspot.com",
  messagingSenderId: "1043638981316",
  appId: "1:1043638981316:web:3bc95b841d0a01498c2f91",
}

export const firebaseProdConfig = {
  apiKey: "AIzaSyBaqRjZFOyGFKAnxVurwyzSnY-HCiMdsGg",
  authDomain: "work-diary-online.firebaseapp.com",
  projectId: "work-diary-online",
  storageBucket: "work-diary-online.appspot.com",
  messagingSenderId: "971848885288",
  appId: "1:971848885288:web:09d76c1069dd1bcd34411d",
  measurementId: "G-JJBBY9TE40",
}
