import React, { createContext, useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase/firebase"
import { getUserData } from "../api/user"

export const AuthedUserContext = createContext<any>({
  user: undefined,
  userData: [],
  loading: true,
})

export const AuthedUserProvider = ({ children }: { children: JSX.Element }) => {
  const [user, loading] = useAuthState(auth)
  const [loadingUserData, setLoadingUserData] = useState<boolean>(false)
  const [userData, setUserData] = useState({})

  useEffect(() => {
    setLoadingUserData(true)
    const fetchUser = async () => {
      if (user?.uid) {
        const data = await getUserData(user.uid)
        setUserData(data)
        setLoadingUserData(false)
      }
    }
    fetchUser()
  }, [user])

  return (
    <AuthedUserContext.Provider
      value={[user, loading, userData, loadingUserData]}
    >
      {children}
    </AuthedUserContext.Provider>
  )
}
