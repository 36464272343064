import styled from "styled-components"

interface NoNotesWrapperProps {
  offset?: number
}

export const NoNotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing.x4};
  border-radius: 5px;
  text-align: center;
  background: ${(props) => props.theme.colors.black.l2};
  margin: 0 0 0
    ${(props: NoNotesWrapperProps) => (props.offset ? props.offset : 0)}px;
`

export const NoNotesHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.theme.spacing.x6};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white.l2};
  margin: ${(props) => props.theme.spacing.x6} 0;
`

export const NoNotesHeadingText = styled.span``

export const NoNotesInstructions = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.x6};
`

export const NoNotesInstructionsText = styled.span`
  color: ${(props) => props.theme.colors.white.l2};
  text-transform: uppercase;
`
